// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

// routing
export const routeMailyticaSmartResponseGUI = 'mailytica-SmartResponseGUI';
export const routeSettings = 'login';

// TranslateService
export const languages = ['German', 'English', 'French', 'Italian', 'Czech', 'Spanish', 'Dutch'];

// Mailytica-API-Service
// export const mailyticaAPIUrl = 'https://api.mailytica.ai/';

export const versionNr = 'v1.77.9';
export const name = 'Mailytica';
export const smartAssist = false;
// export const mailyticaLoginUrl = "https://mailytica.ai";
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

