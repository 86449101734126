import {Metadata} from './MetaData';
import * as constants from 'src/environments/environment';
import {ClientSetting} from './ClientSetting';
import {SmartResponseProposal} from './SmartResponseProposal';

export class MailyticaSmartResponse {
  constructor(
    public topics: string[],
    public availableSmartResponses: AvailableSmartResponses,
    public smartResponseProposals: SmartResponseProposal[],
    public availableFields: AvailableField[],
    public language: string,
    public messageId: string,
    public metadata: Metadata[],
    public classification: Classification,
    public clientSettings: ClientSetting,
    public availableUserActions: AvailableUserAction[],
    public isLocked: boolean = false) {
  }

  public static fromJson(entity: any): MailyticaSmartResponse {

    const topics: string[] = entity.topics;

    if (!topics.includes('Standard')) {
      topics.push('Standard');
    }

    const clientSettings = ClientSetting.fromJson(entity.clientSettings);

    return new MailyticaSmartResponse(
      topics,
      entity.availableSmartResponses,
      entity.smartResponseProposals.map(smartResponseProposal => SmartResponseProposal.fromJson(smartResponseProposal)),
      entity.availableFields.map(availableField => AvailableField.fromJson(availableField)),
      entity.language,
      entity.messageId,
      entity.metadata.map(metadata => Metadata.fromJson(metadata)),
      entity.classification,
      clientSettings,
      entity.availableUserActions.map(availableUserAction => AvailableUserAction.fromJson(availableUserAction)),
      entity.isLocked
    );
  }

  public addSmartAssistProposal() {
    if (constants.smartAssist) {
      this.smartResponseProposals.push(new SmartResponseProposal([], '', [], [], false, '', '', '', '', '', '', '', '', '', 'Smart Assist', 'inactive'));
    }
  }

  public setSmartAssistPredictionMessage(predictionMessage: string) {

    if (constants.smartAssist) {
      this.smartResponseProposals.find(proposal => proposal.topicLabel === 'Smart Assist').smartResponse = predictionMessage;
    }
  }
}

export class AvailableSmartResponses {
  constructor(
    public language: string,
    public topics: Topic[]) {
  }

  public static fromJson(entity: any): AvailableSmartResponses {

    return new AvailableSmartResponses(
      entity.language,
      entity.topics.map(topic => Topic.fromJson(topic))
    );
  }
}

export class Topic {
  constructor(
    public children: Child[],
    public id: string,
    public label: string,
    public smartResponses: SmartResponse[]) {
  }

  public static fromJson(entity: any): Topic {
    return new Topic(
      entity.children.map(child => Child.fromJson(child)),
      entity.id,
      entity.label,
      entity.smartResponses.map(smartResponse => SmartResponse.fromJson(smartResponse))
    );
  }
}

export class Child {
  constructor(
    public children: any[],
    public id: string,
    public label: string,
    public smartResponses: SmartResponse[]) {
  }

  public static fromJson(entity: any): Child {
    return new Child(
      entity.children,
      entity.id, entity.label,
      entity.smartResponses.map(smartResponse => SmartResponse.fromJson(smartResponse))
    );
  }
}

export class SmartResponse {
  constructor(
    public id: string,
    public internalNotice: string,
    public label: string,
    public text: string) {
  }

  public static fromJson(entity: any): SmartResponse {
    return new SmartResponse(
      entity.id,
      entity.internalNotice,
      entity.label,
      entity.text
    );
  }
}

export class Attachment {
  constructor(
    public attachmentApiUrl: string,
    public filename: string
  ) {
  }

  public static fromJson(entity: any): Attachment {

    return new Attachment(
      entity.attachmentApiUrl,
      entity.filename
    );
  }
}

export class AvailableField {
  constructor(
    public displayInClientStatus: string,
    public fieldNameId: string,
    public fieldNameLabel: string,
    public fieldType: string
  ) {
  }

  public static fromJson(entity: any): AvailableField {

    return new AvailableField(
      entity.displayInClientStatus,
      entity.fieldNameId,
      entity.fieldNameLabel,
      entity.fieldType
    );
  }
}

export class Classification {
  constructor(
    public confidence: number,
    public label: string,
    public topicId: string
  ) {
  }

  public static fromJson(entity: any): Classification {
    return new Classification(
      entity.confidence,
      entity.label,
      entity.topicId
    );
  }
}

export class AvailableUserAction {

  constructor(
    public actionId: string,
    public actionLabel: string,
    public actionInputFields: string[]
  ) {
  }

  public static fromJson(entity: any): AvailableUserAction {

    return new AvailableUserAction(
      entity.actionId,
      entity.actionLabel,
      entity.actionInputFields
    );
  }

  public allNecessaryMetadataSet(availableMetadata: Metadata[]): boolean {

    let unsetParameter = false;

    // if min. metadataValue is empty --> pop-up has to be opened
    this.getRequiredMetadata(availableMetadata).forEach(metadata => {
      if (metadata.metadataValue === '') {
        unsetParameter = true;
      }
    });

    return unsetParameter;
  }

  public getRequiredMetadata(availableMetadata: Metadata[]): Metadata[] {

    const requiredMetadata: Metadata[] = [];

    this.actionInputFields.forEach(actionInputField => {

      requiredMetadata.push(new Metadata(actionInputField, '', '', ''));

      availableMetadata.forEach(metadata => {

        // replace element in array, if matching metadata exists
        if (actionInputField === metadata.metadataFieldId) {
          requiredMetadata[requiredMetadata.length - 1] = metadata;
        }
      });
    });

    return requiredMetadata;
  }
}

