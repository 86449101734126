import {Metadata} from './MetaData';
import {Attachment} from './MailyticaSmartResponse';

export class SmartResponseProposal {

  constructor(
    public attachments: Attachment[],
    public autoReplyStatus: string,
    public bccRecipients: string[],
    public ccRecipients: string[],
    public hasAttachments: boolean,
    public id: string,
    public label: string,
    public note: string,
    public receivingAddress: string,
    public salutation: string,
    public salutationStatus: string,
    public smartResponse: string,
    public smartResponseText: string,
    public subject: string,
    public topicLabel: string,
    public forwardReceivedAttachmentsStatus: string
  ) {
  }

  public static fromJson(entity: any): SmartResponseProposal {

    let bccRecipients = [];
    if (entity.bccRecipient !== undefined && entity.bccRecipient.trim().length > 0) {

      bccRecipients = entity.bccRecipient.split(';');
    }

    let ccRecipients = [];
    if (entity.ccRecipient !== undefined && entity.ccRecipient.trim().length > 0) {

      ccRecipients = entity.ccRecipient.split(';');
    }

    let smartResponseText = entity.smartResponseTextParagraphs;
    if (smartResponseText === undefined) {
      smartResponseText = entity.smartResponseText;
    }

    let smartResponse = entity.smartResponseParagraphs;
    if (smartResponse === undefined) {
      smartResponse = entity.smartResponse;
    }

    return new SmartResponseProposal(
      entity.attachments.map(attachment => Attachment.fromJson(attachment)),
      entity.autoReplyStatus,
      bccRecipients,
      ccRecipients,
      entity.hasAttachments,
      entity.id,
      entity.label,
      entity.note,
      entity.receivingAddress,
      entity.salutation,
      entity.salutationStatus,
      smartResponse,
      smartResponseText,
      entity.subject,
      entity.topicLabel,
      entity.forwardReceivedAttachmentsStatus
    );
  }

  public checkProposalTextForMetadataVariables(availableMetadata: Metadata[]): Metadata[] {

    const metadataList: Metadata[] = [];

    for (let i = 0; i < this.smartResponse.length - 1; i++) {

      if (this.smartResponse.charAt(i) === '{' && this.smartResponse.includes('}')) {

        const variable = this.smartResponse.substring(i + 1, this.smartResponse.indexOf('}', i));
        const metadata = availableMetadata.find(md => md.metadataFieldId === variable);

        if (metadata !== undefined) {
          metadataList.push(metadata);
        } else {
          metadataList.push(new Metadata(variable, variable, 'String', ''));
        }
      }
    }

    return metadataList;
  }

  public checkSubjectForMetadataVariables(availableMetadata: Metadata[]): Metadata[] {

    const metadataList: Metadata[] = [];

    for (let i = 0; i < this.subject.length - 1; i++) {

      if (this.subject.charAt(i) === '{' && this.subject.includes('}')) {

        const variable = this.subject.substring(i + 1, this.subject.indexOf('}', i));
        const metadata = availableMetadata.find(md => md.metadataFieldId === variable);

        if (metadata !== undefined) {
          metadataList.push(metadata);
        } else {
          metadataList.push(new Metadata(variable, variable, 'String', ''));
        }
      }
    }

    return metadataList;
  }

  public replaceProposalVariables(metadataVariables: Metadata[]): string {

    let newProposalText = this.smartResponse;

    metadataVariables.forEach(variable => {
      newProposalText = newProposalText.replace('{' + variable.metadataFieldId + '}', variable.metadataValue);
    });

    return newProposalText;
  }

  public replaceSubjectVariables(metadataVariables: Metadata[]): string {

    let newProposalText = this.subject;

    metadataVariables.forEach(variable => {
      newProposalText = newProposalText.replace('{' + variable.metadataFieldId + '}', variable.metadataValue);
    });

    return newProposalText;
  }
}
